import { FormattedMessage, injectIntl } from 'react-intl';
import {
  Table,
  TableBody as TableBodyStep,
  TableCell as TableCellStep,
  TableHeader,
  TableRow as TableRowStep,
} from './StepItem';

import Label, { ImageLabel } from './Label';
import React, { useState } from 'react';
import { Viewport } from 'components/ViewportProvider';
import styled from 'styled-components';
import utilsMessages from 'utils/messages';
import { CartItem, ProductNoteInput, SingleSupplierCart } from 'types/schema';
import {
  ProductsQuantityType,
  TableRowContentDesktop,
  TableRowContentMobile,
  TableRowWithTracking,
} from './OrderTableRow';
import ModalNote from './ModalNote';

const TableBody = styled(TableBodyStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;

const TableCell = styled(TableCellStep)`
  @media (min-width: 575px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 230px;
    `
        : ``};
  }
  @media (min-width: 769px) {
    ${({ fixwidth }) =>
      fixwidth
        ? `
      width: 320px;
    `
        : ``};
  }
  @media (max-width: 575px) {
    &:not(.hideOnMobile) {
      display: block;
    }
    &.quantity-row {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
    &.padding-right-less {
      padding-right: 0;
    }
    padding: 5px 10px;
  }
`;

const TableRow = styled(TableRowStep)`
  @media (max-width: 575px) {
    display: block;
  }
`;
interface IOrderTable {
  singleSupplierCart: SingleSupplierCart;
  disabled?: boolean;
  name: string;
  onRemoveAllItem?: (singleSupplierCart: SingleSupplierCart) => void;
  onRemoveItem?: (cartItem: CartItem) => void;
  productsQuantity: ProductsQuantityType;
  shouldTrackCartItem?: boolean;
  setProductNote?: (data: ProductNoteInput) => void;
  noteItemLoading?: boolean;
}

const OrderTable = (props: IOrderTable) => {
  const {
    shouldTrackCartItem,
    disabled,
    name,
    onRemoveAllItem,
    productsQuantity,
    onRemoveItem,
    singleSupplierCart,
    setProductNote,
    noteItemLoading,
  } = props;
  const { supplier } = singleSupplierCart;

  const [isOpenNoteModal, setIsOpenNoteModal] = useState(false);
  const [previewProduct, setPreviewProduct] = useState<CartItem | null>(null);

  const onRemoveProduct = (item: CartItem) => () => {
    if (item?.product && onRemoveItem) {
      onRemoveItem({
        product: { ...item.product, supplier: supplier },
      });
    }
  };

  const onCloseNote = () => {
    setPreviewProduct(null);
    setIsOpenNoteModal(false);
  };
  const onNoteSubmit = (note) => {
    if (setProductNote) {
      setProductNote({
        note: note,
        productId: previewProduct?.product?.id || '',
      });
    }
    onCloseNote();
  };

  if (!singleSupplierCart || !singleSupplierCart?.items) return null;
  const { items } = singleSupplierCart;

  if (!supplier) return null;

  return (
    <>
      <Viewport.Consumer>
        {({ width }) => {
          if (width < 575) {
            return (
              <Table>
                <TableBody>
                  {items.map((item) => {
                    if (item.product && productsQuantity[item.product.id] > 0) {
                      if (shouldTrackCartItem) {
                        return (
                          <TableRowWithTracking product={item.product} key={`${name}-${item.product.id}`}>
                            <TableRowContentMobile
                              name={name}
                              item={item}
                              supplier={supplier}
                              disabled={disabled}
                              onRemoveProduct={onRemoveProduct}
                              productsQuantity={productsQuantity}
                              openNote={() => {
                                setPreviewProduct(item);
                                setIsOpenNoteModal(true);
                              }}
                              setProductNote={setProductNote}
                            />
                          </TableRowWithTracking>
                        );
                      }
                      return (
                        <TableRow key={`${name}-${item.product.id}`}>
                          <TableRowContentMobile
                            name={name}
                            item={item}
                            supplier={supplier}
                            disabled={disabled}
                            onRemoveProduct={onRemoveProduct}
                            productsQuantity={productsQuantity}
                            openNote={() => {
                              setPreviewProduct(item);
                              setIsOpenNoteModal(true);
                            }}
                            setProductNote={setProductNote}
                          />
                        </TableRow>
                      );
                    }
                    return null;
                  })}
                </TableBody>
              </Table>
            );
          }
          return (
            <Table>
              <TableHeader>
                <TableRow>
                  <TableCell>
                    <ImageLabel>
                      <FormattedMessage {...utilsMessages.itemName} />
                    </ImageLabel>
                  </TableCell>
                  <TableCell colSpan="2" />
                  <TableCell>
                    <Label>
                      <FormattedMessage {...utilsMessages.price} />
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <FormattedMessage {...utilsMessages.quantity} />
                    </Label>
                  </TableCell>
                  <TableCell>
                    <Label>
                      <FormattedMessage {...utilsMessages.totalInclVAT} />
                    </Label>
                  </TableCell>
                  {!disabled && (
                    <TableCell plain>
                      <a
                        style={{ color: '#FE5043', whiteSpace: 'nowrap' }}
                        href="javascript:void(0)"
                        onClick={() => onRemoveAllItem && onRemoveAllItem(singleSupplierCart)}
                      >
                        <FormattedMessage {...utilsMessages.deleteAllItems} />
                      </a>
                    </TableCell>
                  )}
                </TableRow>
              </TableHeader>
              <TableBody>
                {items.map((item) => {
                  if (item.product && productsQuantity[item.product.id] > 0) {
                    if (shouldTrackCartItem) {
                      return (
                        <TableRowWithTracking product={item.product} key={`${name}-${item.product.id}`}>
                          <TableRowContentDesktop
                            name={name}
                            item={item}
                            supplier={supplier}
                            disabled={disabled}
                            onRemoveProduct={onRemoveProduct}
                            productsQuantity={productsQuantity}
                            openNote={() => {
                              setPreviewProduct(item);
                              setIsOpenNoteModal(true);
                            }}
                            setProductNote={setProductNote}
                          />
                        </TableRowWithTracking>
                      );
                    }
                    return (
                      <TableRow key={`${name}-${item.product.id}`}>
                        <TableRowContentDesktop
                          name={name}
                          item={item}
                          supplier={supplier}
                          disabled={disabled}
                          onRemoveProduct={onRemoveProduct}
                          productsQuantity={productsQuantity}
                          openNote={() => {
                            setPreviewProduct(item);
                            setIsOpenNoteModal(true);
                          }}
                          setProductNote={setProductNote}
                        />
                      </TableRow>
                    );
                  }
                  return null;
                })}
              </TableBody>
            </Table>
          );
        }}
      </Viewport.Consumer>
      <ModalNote
        headerLabel={previewProduct?.product?.name || ''}
        isOpen={isOpenNoteModal}
        confirmLoading={noteItemLoading}
        onSubmit={onNoteSubmit}
        onClose={onCloseNote}
        note={previewProduct?.product?.note || ''}
      />
    </>
  );
};

export default injectIntl(OrderTable);
